<template>
  <div class="module">
    <cube-scroll-nav-bar :current="seriesId" :labels="seriesIds" :txts="seriesLabels" v-if="seriesIds.length > 0" @change="changeSeries">
      <i slot-scope="props">{{ props.txt }}</i>
    </cube-scroll-nav-bar>
    <div v-if="seriesId === 'PROFILE'">
      <ul class="ul contact">
        <li class="div clear" style="border-top:0;">
          <div style="float:right;padding:6px;"><img :src="imgUrl + '/company/1.jpg'" width="160" /></div>
          <p class="p"><span style="font-weight:bold;">FLEXY EYEWEAR</span> is a young and energetic company founded in 2008. The main staffs are all experienced in the optical frame business over 10 years.</p>
          <p class="p">Through our efforts, Flexy Eyewear now is well organized with Sales Service, Product Design, QC, Production Control System, IT department and so on. Each department has specific functions and support each other based on the Management Information System. It guarantees that all
            the team is a well-oiled machine.</p>
          <p class="p">Each year, we attend MIDO, SILMO and HK Fair, we have enjoyed a good reputation in the field of optical frame suppliers. </p>
          <p class="p">As a comprehensive supplier of optical frames, Flexy Eyewear has set up one group with several partners. The products range over metal (200,000pcs/Month), acetate (150,000pcs/Month), injection (100,000pcs/month) with competitive price.</p>
          <p class="p">&nbsp;</p>
          <p class="p">Customer care is our top priority. </p>
          <p class="p">Quality and trust are our basal policy. </p>
          <p class="p">Enjoy work, enjoy life is our pursuit.</p>
          <p class="p">&nbsp;</p>
          <p class="p" style="font-weight:bold;">Flexy Eyewear is looking forward to the cooperation with you.</p>
        </li>
        <li class="div odd">
          <p class="p"><span style="font-weight:bold;">Fax:</span>&nbsp;&nbsp;
            {{ company.fax }}
          </p>
          <p class="p"><span style="font-weight:bold;">Address:</span>&nbsp;&nbsp;
            {{ company.address }}
          </p>
        </li>
        <li class="div">
          <img :src="imgUrl + '/company/' + item + '.jpg'" width="100%" v-for="item in profileImgs" :key="'profileImg' + item" />
        </li>
      </ul>
    </div>
    <div v-else-if="seriesId === 'HISTORY'">
      <div class="item">
        <div class="czzl">
          <h5>◆&nbsp;&nbsp;2015</h5>
          <p><span style="font-weight:bold; color:blue;">Join us</span> for a magic 2015.</p>
          <h5>◆&nbsp;&nbsp;2014</h5>
          <p>Full of energy to a higher goal.</p>
          <h5>◆&nbsp;&nbsp;2013</h5>
          <p>Turnover exceeded $5,000,000.</p>
          <h5>◆&nbsp;&nbsp;2012</h5>
          <p>Start the cooperation with Eschenbach of Germany.</p>
          <h5>◆&nbsp;&nbsp;2011</h5>
          <p>Sales Volume exceeded 1,000,000PCS.</p>
          <h5>◆&nbsp;&nbsp;2010</h5>
          <p>Start the cooperation with Optic 2000.</p>
          <h5>◆&nbsp;&nbsp;2009</h5>
          <p>Turnover exceeded $1,000,000.</p>
          <h5>◆&nbsp;&nbsp;2008</h5>
          <p>The company founded.</p>
        </div>
      </div>
    </div>
    <div v-else-if="seriesId === 'ORGANIZATION'">
      <div class="item">
        <img :src="imgUrl + '/company/flow.jpg'" width="100%" />
      </div>
    </div>
    <div v-else-if="seriesId === 'FAIR'">
      <div class="item">
        <img :src="imgUrl + '/company/fair_' + item + '.jpg'" width="100%" v-for="item in fairImgs" :key="'fairImg' + item" />
      </div>
    </div>
    <div v-else-if="seriesId === 'CERTIFICATE'">
      <div class="item">
        <img :src="imgUrl + '/company/zs_' + item + '.jpg'" width="100%" v-for="item in zsImgs" :key="'zsImg' + item" />
      </div>
    </div>
  </div>
</template>

<script>
const resource = '/mobile/index/index'
export default {
  name: 'TemplateAboutUs',
  components: {
  },
  props: {
  },
  computed: {
  },
  data () {
    return {
      loading: false,
      toast: null,
      seriesId: 0,
      seriesIds: [],
      seriesLabels: [],
      company: null,
      imgUrl: '',
      profileImgs: [],
      fairImgs: [],
      zsImgs: []
    }
  },
  async mounted () {
    this.toast = this.$createToast({
      txt: 'Loading...',
      mask: true
    })
    await this.load()
    this.loading = true
  },
  methods: {
    // 加载列表
    async load () {
      this.toast.show()
      const result = await this.$http.post(resource, {
        op: 'loadAboutUs',
        seriesId: this.seriesId
      })
      this.seriesId = result.data.seriesId
      this.seriesIds = result.data.seriesIds
      this.seriesLabels = result.data.seriesLabels
      this.company = result.data.company
      this.imgUrl = result.data.imgUrl
      this.profileImgs = result.data.profileImgs
      this.fairImgs = result.data.fairImgs
      this.zsImgs = result.data.zsImgs
      this.toast.hide()
    },
    changeSeries (e) {
      this.seriesId = e
    }
  }
}
</script>

<style>
.module {
  background: #ffffff;
  margin-bottom: 6px;
  position: relative;
}

.module h3 {
  /**background: #ebebeb url(../images/headline-background2.png) repeat-x top;*/
  background: #666;
  border-top: 1px solid #ffffff;
  border-bottom: 2px solid #ccc;
  margin: 0;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 6px 6px;
  font-weight: bold;
  color: #fff;
}

.module .p {
  padding: 2px 12px;
  font-size: 14px;
  line-height: 18px;
}

.module .div {
  padding: 7px 0;
  font-size: 14px;
  line-height: 18px;
}

.module .more {
  position: absolute;
  right: 6px;
  top: 8px;
}

.module .more a {
  color: #fff;
}

.module .more a:hover {
  color: #fff;
  text-decoration: underline;
}

.module .ul li,
.module .ol li {
  list-style: none;
  padding: 6px;
  border-top: 1px solid #cdcdcc;
  font-size: 11px;
  font-weight: bold;
}

.clear:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

* html .clear {
  zoom: 1;
}

.odd,
.odd td,
.comments {
  background: #f4f4f4;
  text-shadow: 1px 1px 0 #fff;
}

.czzl {
  line-height: 25px;
  padding-bottom: 50px;
  padding-left: 10px;
}

.czzl h5 {
  font-weight: bold;
  padding: 0;
  margin: 0;
  color: #651111;
  font-size: 14px;
  text-align: left;
}

.czzl p {
  margin-bottom: 6px;
  line-height: 20px;
  padding: 2px 0;
  font-size: 14px;
  text-align: left;
}
</style>
