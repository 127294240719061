<template>
  <div v-if="loading">
    <div class="productDetailContainer">
      <div class="productDetailHeader">
        <div class="productDetailHeaderModel">{{ faceOption.title }}</div>
        <div class="productDetailHeaderClose"><i class="cubeic-close" @click="closeDetail"></i></div>
      </div>
      <div class="productDetail">
        <div v-if="faceOption.type == 'tryon'" style="text-align: center; margin:20px auto;">
          <!-- Tryon Begin. -->
          <div :style="'width:' + clientWidth + 'px'" style="position: relative;text-align:center;margin: auto;" v-if="faceOption.tryonData">
            <div v-if="faceOption.tryonData">
              <img :src="faceOption.tryonData.faceInfo.url" :width="faceOption.tryonData.bigFace.width" />
              <img :class="faceOption.animation" :src="faceOption.tryonData.glassesInfo.url" :width="faceOption.tryonData.glassesInfo.width"
                :style="'position:absolute;left:' + faceOption.tryonData.glassesOffset.x + 'px;top:' + faceOption.tryonData.glassesOffset.y + 'px; transform: rotateZ(' + faceOption.tryonData.faceInfo.roll + ') rotateY(' + faceOption.tryonData.faceInfo.yaw + ') rotateX(' + faceOption.tryonData.faceInfo.pitch + '); -webkit-transform: rotateZ(' + faceOption.tryonData.faceInfo.roll + ') rotateY(' + faceOption.tryonData.faceInfo.yaw + ') rotateX(' + faceOption.tryonData.faceInfo.pitch + ');'"
                @animationend="tryonAnimationEnd" />
              <div style="cursor: pointer; color: #fff; position: absolute; right: 0; top: 0; background: rgba(0, 0, 0, .5); padding: .5em .5em; font-size: 14px; text-align: center;">
                <p style="margin-bottom: 5px;">PD: {{ faceOption.tryonData.faceInfo.pd }}mm</p>
                <div>
                  <cube-button :inline="true" style="height:100%;" :primary="true" :light="true" @click="showMenus">
                    <i class="cubeic-more" style="transform-origin: center"></i>
                  </cube-button>
                </div>
                <!-- <Dropdown trigger="click" placement="bottom-end" class="faceDropdown"> <a style="cursor: pointer; color: #fff; background: rgba(0, 0, 0, .5); padding: .6em .8em; width:100%;">
                    <Icon type="ios-more" size="14"></Icon>
                  </a>
                  <div slot="list">
                    <div class="faceDropdownItem" @click="changePhoto">Change Photo</div>
                    <div class="faceDropdownItem" @click="editPd">Adjust PD</div>
                    <div class="faceDropdownItem" @click="deleteFace" v-if="faceOption.currentFace.cuserId > 0 && faceOption.currentFace.allowDelete">Delete the Photo</div>
                  </div>
                </Dropdown> -->
              </div>
            </div>
            <div style="position: absolute; top: 0px; left: 0;">
              <div style="color: #fff; background: rgba(0, 0, 0, .5); padding: 10px; text-align: center;line-height: 20px;">
                <p style="font-size: 14px;">{{ faceOption.item.model }}</p>
                <p style="font-size: 12px !important;">
                  <template v-if="faceOption.item.size"> {{ faceOption.item.size }} </template>
                  <template v-else-if="faceOption.item.main"> {{ faceOption.item.main.size }} </template>
                </p>
              </div>
            </div>
            <div class="thumbnailWrapper" v-if="faceOption.frames.length > 1">
              <div class="frameBtn">
                <div v-if="faceOption.frameLeftBtn" @click="changeFrameIndex('left')" class="btn">
                  <cube-button :inline="true" style="height:100%;width:100%">
                    <i class="cubeic-back"></i>
                  </cube-button>
                </div>
              </div>
              <div class="thumbnail" v-if="item.show" :class="(key == faceOption.frameIndex) ? 'active' : ''" @click="changeFrame(item, key)" v-for="(item, key) in faceOption.frames" :key="'thumbnail' + key">
                <img :src="item.url" />
              </div>
              <div class="frameBtn">
                <div v-if="faceOption.frameRightBtn" @click="changeFrameIndex('right')" class="btn">
                  <cube-button :inline="true" style="height:100%;width:100%">
                    <i class="cubeic-arrow"></i>
                  </cube-button>
                </div>
              </div>
            </div>
            <div v-if="faceOption.showPrevNext">
              <div class="btn" style="position: absolute; left: 10px; top: 320px; height: 60px; width: 30px;" v-if="faceOption.prevModel">
                <i-button size="small" style="height:100%;width:100%" @click="openFaceDialog(faceOption.prevModel, 'list')">
                  <icon type="ios-arrow-back" size="20"></icon>
                </i-button>
              </div>
              <div class="btn" style="position: absolute; right: 10px; top: 320px; height: 60px; width: 30px;" v-if="faceOption.nextModel">
                <i-button size="small" style="height:100%;width:100%" @click="openFaceDialog(faceOption.nextModel, 'list')">
                  <icon type="ios-arrow-forward" size="20"></icon>
                </i-button>
              </div>
            </div>
            <div style="position: absolute; bottom: 50px; left: 0;width:100%; background: none; padding: .8em; text-align: center;" v-if="!faceOption.openByDetail">
              <div style="width:100px;margin:auto;">
                <p style="font-size: 14px; background: rgba(0, 0, 0, .5);color:#fff;padding:5px 10px; cursor:pointer; text-decoration:underline;" @click="showDetail(faceOption.item.model, faceOption.item, 1)">
                  View Details
                </p>
              </div>
            </div>
          </div>
          <!-- Tryon End. -->
        </div>
        <div v-else>
          <div v-if="faceOption.type == 'pd'" style="text-align: center; position: relative; height: 180px;width:80%;margin:auto;text-align: center;">
            <!-- PD Begin. -->
            <p style="font-size:14px;margin:20px;">Set your PD if you know it.</p>
            <div style="text-align: center;width:100px;margin:10px auto;">
              <cube-select title=" " cancelTxt="Cancel" confirmTxt="Confirm" v-model="faceOption.pd" :options="pds"> </cube-select>
            </div>
            <p style="text-align: left; padding: 5px; border: 1px solid #ccc; background: #f1f1f1; opacity: .8;font-size:14px;line-height: 20px;">Tip: Adding a PD value helps ensure the selected frame is proportional to your face.</p>
            <div style="position: absolute; bottom: 0; left: 0;">
              <cube-button :inline="true" style="height:100%;width:100%" @click="faceBackToCropper">
                <i class="cubeic-back"></i> BACK
              </cube-button>
            </div>
            <div style="position: absolute; bottom: 0; right: 0;">
              <cube-button :inline="true" style="height:100%;width:100%" @click="faceUpload">
                <i class="cubeic-arrow"></i> CONTINUE
              </cube-button>
            </div>
            <!-- PD End. -->
          </div>
          <div v-else>
            <div v-if="faceOption.type == 'cropper'" style="min-height: 650px; position: relative;">
              <!-- Cropper Begin. -->
              <div style="height: 574px; position: relative;">
                <img id="cropperImage" :src="faceOption.imgSrc" style="height: 574px;" />
                <div style="position: absolute; bottom: 0px; right: 0;">
                  <span style="color: #00b3a8; background: rgba(0, 0, 0, .3); padding: .2em .5em; cursor: pointer; font-size: 16px;" @click="faceRotate">
                    <icon type="ios-refresh-outline" size="16"></icon>
                    Rotate
                  </span>
                </div>
              </div>
              <div style="text-align: center; font-size: 20px; padding: 10px;">
                <p>Drag the box to crop the photo.</p>
              </div>
              <div style="position: absolute; bottom: 0; left: 0;">
                <i-button type="default" @click="faceBackToUpload">
                  <icon type="ios-arrow-back"></icon> BACK
                </i-button>
              </div>
              <div style="position: absolute; bottom: 0; right: 0;">
                <i-button type="default" @click="faceCropper"> CROP <icon type="ios-arrow-forward"></icon> </i-button>
              </div>
              <!-- Cropper End. -->
            </div>
            <div v-else>
              <div v-if="faceOption.faces.length < 1">
                <!-- Upload Begin. -->
                <p>Click on "UPLOAD PHOTO..." below to upload a photo.</p>
                <p style="padding: 5px; border: 1px solid #ccc; background: #f1f1f1; opacity: .8">Tip: For best results,please take off your glasses and keep your face forward.</p>
                <div style="text-align: center;">
                  <input type="file" id="faceFile" name="file" accpet=".jpg,.jpeg,.png,image/jpeg,image/png" style="display: none;" @change="openFaceCropper($event)"></input>
                  <label for="faceFile">
                    <i-button type="primary" @click="$('#faceFile').click()">
                      <icon type="ios-cloud-upload-outline"></icon> Upload Photo...
                    </i-button>
                  </label>
                </div>
                <!-- Upload End. -->
              </div>
              <!-- Photos Begin. -->
              <div v-else>
                <p style="font-size:14px;margin:20px;">PLEASE CHOOSE A PHOTO TO WEAR GLASSES</p>
                <ul class="face_photos">
                  <li v-for="item in faceOption.faces" @click="setCurrentFace(item)" :key="'facePhoto_' + item.id">
                    <img :src="item.url" width="100%" />
                  </li>
                </ul>
              </div>
              <!-- Photos End. -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/animate.min.css"
const resource = '/face/index/index'
export default {
  name: 'TemplateProductTryon',
  components: {
  },
  props: {
    product: {
      type: Object,
      default () {
        return product
      }
    }
  },
  computed: {
  },
  data () {
    return {
      loading: false,
      toast: null,
      clientWidth: 300,
      pds: [],
      faceOption: {
        title: 'TRY GLASSES ON YOUR FACE',
        type: 'upload',
        cropper: null,
        imgSrc: '',
        file: '',
        pd: 62,
        faces: [],
        frames: [],
        item: null,
        openByDetail: true,
        models: [],
        prevModel: '',
        nextModel: '',
        showPrevNext: false,
        currentFrame: null,
        currentFace: null,
        tryonData: null,
        frameLength: 2,
        frameLeftBtn: false,
        frameRightBtn: false,
        frameIndex: 0,
        animation: ''
      }
    }
  },
  async mounted () {
    this.toast = this.$createToast({
      txt: 'Loading...',
      mask: true
    })
    this.faceOption.item = this.product
    this.clientWidth = parseInt(document.body.clientWidth * 90 / 100)
    for (let i = 40; i <= 80; i++) {
      this.pds.push(i)
    }
    await this.load(this.product.model)
  },
  methods: {
    // 加载列表
    async load (model) {
      this.toast.show()
      this.loading = false
      const result = await this.$http.post(resource, {
        op: 'load',
        model: model
      })
      if (result.success === 1) {
        this.faceOption.faces = result.data.faces;
        this.faceOption.frames = result.data.frames;
        this.faceOption.currentFace = result.data.currentFace;
        this.faceOption.currentFrame = result.data.frames[0];
        this.faceOption.frameIndex = 0;
        const frameLength = this.faceOption.frameLength
        if (this.faceOption.frames.length > frameLength) {
          this.faceOption.frames.forEach(function (item, key) {
            if (key >= frameLength) {
              item.show = false;
            }
          })
          this.faceOption.frameRightBtn = true;
          this.faceOption.frameLeftBtn = false;
        } else {
          this.faceOption.frameRightBtn = false;
          this.faceOption.frameLeftBtn = false;
        }
        if (this.faceOption.currentFace) {
          this.tryon();
        }
        this.toast.hide()
      } else {
        this.toast.hide()
        this.$createToast({
          txt: 'Sorry,this frame can not try on!',
          type: 'txt'
        }).show()
      }
      this.loading = true

    },

    async tryon () {
      if (this.faceOption.currentFrame && this.faceOption.currentFace) {
        this.faceOption.type = 'tryon';
        this.faceOption.title = 'TRY GLASSES ON YOUR FACE';
        this.toast.show()
        const result = await this.$http.post(resource, {
          op: 'tryon',
          frame: this.faceOption.currentFrame,
          face: this.faceOption.currentFace,
          faceWidth: this.clientWidth
        })
        this.faceOption.tryonData = result.data;
        var animation = 'animated rotateInUpLeft';
        var length = this.faceOption.frames.length;
        var count = 0;
        for (var i = 0; i < length; i++) {
          var item = this.faceOption.frames[i];
          if (item.show) {
            count++;
            if (this.faceOption.currentFrame.id == item.id) {
              break;
            }
          }
        }
        if (count > 2) {
          animation = 'animated rotateInUpRight';
        }
        this.faceOption.animation = animation;
        this.toast.hide()
      }
    },

    tryonAnimationEnd () {
      this.faceOption.animation = '';
    },

    changeFrameIndex (type) {
      if (type == 'left') {
        this.faceOption.frameIndex--;
        this.faceOption.frameIndex = this.faceOption.frameIndex < 0 ? 0 : this.faceOption.frameIndex;
        if (!this.faceOption.frames[this.faceOption.frameIndex].show) {
          this.faceOption.frames[this.faceOption.frameIndex + this.faceOption.frameLength].show = false;
          this.faceOption.frames[this.faceOption.frameIndex].show = true;
        }
      } else {
        this.faceOption.frameIndex++;
        this.faceOption.frameIndex = this.faceOption.frameIndex > this.faceOption.frames.length - 1 ? this.faceOption.frames.length - 1 : this.faceOption.frameIndex;
        if (!this.faceOption.frames[this.faceOption.frameIndex].show) {
          this.faceOption.frames[this.faceOption.frameIndex - this.faceOption.frameLength].show = false;
          this.faceOption.frames[this.faceOption.frameIndex].show = true;
        }
      }
      this.faceOption.currentFrame = this.faceOption.frames[this.faceOption.frameIndex];
      this.toggleFrameBtn();
      this.tryon();
    },

    toggleFrameBtn () {
      if (this.faceOption.frames.length <= this.faceOption.frameLength) {
        this.faceOption.frameLeftBtn = false;
        this.faceOption.frameRightBtn = false;
      } else {
        if (this.faceOption.frameIndex > 0) {
          this.faceOption.frameLeftBtn = true;
        } else {
          this.faceOption.frameLeftBtn = false;
        }
        if (this.faceOption.frameIndex < this.faceOption.frames.length - 1) {
          this.faceOption.frameRightBtn = true;
        } else {
          this.faceOption.frameRightBtn = false;
        }
      }
    },

    changeFrame (item, key) {
      this.faceOption.animation = '';
      this.faceOption.currentFrame = item;
      this.faceOption.frameIndex = key;
      this.toggleFrameBtn();
      this.tryon();
    },

    showMenus () {
      this.$createActionSheet({
        title: '',
        cancelTxt: 'Close',
        data: [
          {
            content: 'Change Photo',
          },
          {
            content: 'Adjust PD',
          }
        ],
        onSelect: (item, index) => {
          if (index === 0) {
            this.changePhoto()
          } else if (index === 1) {
            this.editPd()
          }
        }
      }).show()
    },

    changePhoto () {
      this.faceOption.type = 'upload';
    },

    setCurrentFace (item) {
      this.faceOption.tryonData = null;
      this.faceOption.currentFace = item;
      this.tryon();
    },

    editPd () {
      if (this.faceOption.cropper) {
        this.faceOption.cropper.destroy();
        this.faceOption.cropper = null;
      }
      this.faceOption.file = '';
      this.faceOption.title = 'ALMOST DONE';
      this.faceOption.type = 'pd';
      if (this.faceOption.currentFace) {
        this.faceOption.pd = parseInt(this.faceOption.currentFace.pd);
      } else {
        this.faceOption.pd = 62;
      }
    },

    async faceUpload () {
      var pd = parseInt(this.faceOption.pd);
      if (pd > 0) {
        if (this.faceOption.file) {
          var data = {
            op: 'upload',
            file: this.faceOption.file,
            pd: pd
          }
        } else if (this.faceOption.currentFace) {
          var data = {
            op: 'updatePd',
            faceId: this.faceOption.currentFace.id,
            pd: pd
          }
        } else {
          return false;
        }
        this.toast.show()
        const result = await this.$http.post(resource, data)
        if (data.op == 'upload') {
          this.faceOption.faces.push(result.data)
          this.faceOption.currentFace = result.data
        } else {
          this.faceOption.currentFace.pd = result.data
        }
        this.faceOption.currentFace.allowDelete = true
        this.toast.hide()
        this.tryon()
      }
    },

    faceBackToCropper () {
      this.faceOption.title = 'TRY GLASSES ON YOUR FACE';
      if (this.faceOption.cropper) {
        this.faceOption.file = '';
        this.faceOption.type = 'cropper';
        // this.$nextTick(function () {
        //   this.faceOption.cropper = new Cropper(document.getElementById('cropperImage'), {
        //     aspectRatio: 3 / 4,
        //     viewMode: 1,
        //     zoomable: false,
        //     movable: true,
        //     dragMode: 'none',
        //     scalable: false,
        //     zoomOnTouch: false,
        //     zoomOnWheel: false,
        //     minCropBoxWidth: 200,
        //     crop: function (e) {
        //     }
        //   })
        // })
      } else {
        this.faceOption.type = 'tryon';
      }
    },

    closeDetail () {
      this.$emit('close')
    },
  }
}
</script>

<style>
.productDetailContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.productDetailHeader {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  font-size: 18px;
}

.productDetailHeaderModel {
  text-align: left;
  padding-left: 20px;
  font-weight: bold;
}

.productDetailHeaderClose {
  text-align: right;
  flex: 1;
  padding-right: 20px;
  font-size: 20px;
}

.productDetail {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.thumbnailWrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  height: 50px;
  background: rgba(0, 0, 0, .5);
  border-top: 1px solid #333;
}

.thumbnail {
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  background: #fff;
  margin: 2px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 87%, .4);
}

.thumbnail img {
  height: 40px;
}

.thumbnail.active {
  box-shadow: 0 0 0 2px #ff9900;
}

.frameBtn {
  display: inline-block;
  width: 30px;
  overflow: hidden;
}

.frameBtn .btn {
  cursor: pointer;
  margin: 2px 0;
  height: 46px;
}


.faceTryon .ivu-modal-header,
.faceUpload .ivu-modal-header {
  padding: 7px 8px;
}

.faceTryon .ivu-modal-close,
.faceUpload .ivu-modal-close {
  top: 2px;
}

.faceUpload .ivu-modal-body {
  padding: 10px;
}

.faceTryon .ivu-modal-body {
  padding: 0;
}

.faceUpload p {
  line-height: 22px;
  margin-bottom: 10px;
  font-size: 14px;
}

.face_photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -.5em;
}

.face_photos li {
  width: 75px;
  height: 100px;
  cursor: pointer;
  margin: 0 .5em 1em;
  transition: all .3s;
  opacity: .8;
  background: #333;
}

li.face_photos_upload>label {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  color: #fff;
  font-size: 40px;
}

.face_photos li:hover {
  opacity: 1;
  transform: scale(1.1)
}

li.face_photos_upload {
  display: flex
}

li.face_photos_upload:hover {
  background: #ccc;
  color: #000
}
</style>
