<template>
  <div>
    <cube-scroll-nav-bar :current="seriesId" :labels="seriesIds" :txts="seriesLabels" v-if="seriesIds.length > 0" @change="changeSeries">
      <i slot-scope="props">{{ props.txt }}</i>
    </cube-scroll-nav-bar>
    <cube-radio-group v-model="hotType" :options="hotTypes" :horizontal="true" v-if="seriesId === 'hot'" style="font-size:14px;" @input="changeHot" />
    <div v-if="loading">
      <div class="productDetailContainer" v-if="detailShow">
        <div class="productDetailHeader">
          <div class="productDetailHeaderModel">{{ model }}</div>
          <div class="productDetailHeaderClose"><i class="cubeic-close" @click="closeDetail"></i></div>
        </div>
        <div class="productDetail">
          <div>
            <ProductDetail :model.sync="model" style="padding-bottom:100px;" v-on:changeModel="changeModel"></ProductDetail>
          </div>
        </div>
      </div>
      <div :id="data.model" class="item" v-for="data in list" :key="data.model">
        <div class="itemImg">
          <img :src="data.mainPhoto" style="width:100%;" @click="showDetail(data)" />
        </div>
        <div class="txt" style="position: relative;">
          <div style="font-weight: bold;">{{ data.model }}</div>
          <div style="margin-top:10px;">{{ data.size }}</div>
          <div style="text-align:center; position:absolute; top:0;right:0; width:100px;font-size:14px; color:peru" v-if="data.tryon" @click="openFaceDialog(data)">
            <i class="cubeic-eye-visible" style="transform-origin: center;"></i> Try On
          </div>
        </div>
      </div>
      <div style="margin:20px auto;">
        <van-pagination v-model="page" :total-items="total" :items-per-page="pageSize" prev-text="Prev" next-text="Next" @change="changePage" />
      </div>
    </div>
    <ProductTryon :product.sync="tryonProduct" v-if="tryonShow" v-on:close="closeFaceDialog" style="z-index: 1001 !important;"></ProductTryon>
  </div>
</template>

<script>
import ProductTryon from '@/templates/product/tryon'
import ProductDetail from '@/templates/product/detail'
import Vue from 'vue';
import { Pagination } from 'vant';
import 'vant/lib/pagination/style';
Vue.use(Pagination);
const resource = '/mobile/index/index'
export default {
  name: 'TemplateProduct',
  components: {
    ProductDetail,
    ProductTryon
  },
  props: {
  },
  computed: {
  },
  data () {
    return {
      loading: false,
      toast: null,
      seriesId: 0,
      seriesIds: [],
      seriesLabels: [],
      total: 0,
      list: [],
      page: 1,
      pageSize: 20,
      hotType: '0',
      hotTypes: [
        {
          label: 'Half Year',
          value: '0'
        }, {
          label: 'One Year',
          value: '1'
        },
      ],
      detailShow: false,
      model: '',
      tryonShow: false,
      tryonProduct: null
    }
  },
  async mounted () {
    this.toast = this.$createToast({
      txt: 'Loading...',
      mask: true
    })
    await this.load()
    this.loadProducts()
    this.loading = true
  },
  methods: {
    // 加载列表
    async load () {
      this.toast.show()
      const result = await this.$http.post(resource, {
        op: 'loadProductSorts',
        seriesId: this.seriesId
      })
      this.seriesId = result.data.seriesId
      this.seriesIds = result.data.seriesIds
      this.seriesLabels = result.data.seriesLabels
      this.toast.hide()
    },
    changeSeries (e) {
      this.seriesId = e
      this.page = 1
      this.loadProducts()
    },
    changeHot (e) {
      this.hotType = e
      this.page = 1
      this.loadProducts()
    },
    async loadProducts () {
      this.toast.show()
      const result = await this.$http.post(resource, {
        op: 'loadProducts',
        seriesId: this.seriesId,
        page: this.page,
        pageSize: this.pageSize,
        type: this.hotType
      })
      this.list = result.data.list
      this.total = result.data.total
      document.body.scrollIntoView()
      this.toast.hide()
    },
    changePage (e) {
      this.page = e
      this.loadProducts()
    },
    showDetail (e) {
      this.model = e.model
      this.detailShow = true
      document.body.style.overflow = 'hidden'
    },
    closeDetail () {
      this.detailShow = false
      document.body.style.overflow = 'auto'
    },
    changeModel (e) {
      this.model = e
    },
    openFaceDialog (data) {
      document.body.style.overflow = 'hidden'
      this.tryonProduct = data
      this.tryonShow = true
    },
    closeFaceDialog () {
      this.tryonShow = false
      document.body.style.overflow = 'auto'
    },
  }
}
</script>

<style>
.item {
  margin: auto 10px;
  text-align: center;
  margin-bottom: 10px;
}

.item .itemImg {
  margin-bottom: 10px;
}

.item .txt {
  line-height: 10px;
  font-size: 14px;
  text-align: center;
}

.item img {
  width: 100% !important;
}

.productDetailContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.productDetailHeader {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  font-size: 18px;
}

.productDetailHeaderModel {
  text-align: left;
  padding-left: 20px;
  font-weight: bold;
}

.productDetailHeaderClose {
  text-align: right;
  flex: 1;
  padding-right: 20px;
  font-size: 20px;
}

.productDetail {
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
