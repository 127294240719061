<template>
  <div v-if="loading">
    <template v-if="product">
      <ProductTryon :product.sync="product" v-if="tryonShow" v-on:close="tryonShow = false"></ProductTryon>
      <div style="text-align:center;" v-if="product.tryon">
        <cube-button :primary="true" style="width:80%;margin:auto;" @click="openFaceDialog">Try on</cube-button>
      </div>
      <van-card v-for="(item, index) in product.photos" :key="'detail_' + index">
        <template #title>
          <div class="cardHeader">{{ item[0].color + " " + item[0].size }}</div>
        </template>
        <template #desc>
          <img :src="photo.url" style="width:100%;" v-for="(photo, key) in item" :key="'detail_' + index + '_' + key" />
        </template>
      </van-card>
      <van-card v-if="product.samecates && product.samecates.length > 0">
        <template #title>
          <div class="cardHeader" style="font-size:14px;">Maybe you are interested in following models</div>
        </template>
        <template #desc>
          <van-swipe class="my-swipe" autoplay="false" @change="changeSwiper">
            <van-swipe-item v-for="(item, index) in product.samecates" :key="index" style="height: 140px;">
              <div style="display:flex;align-items: center; justify-content: space-around;">
                <div v-for="val in item" :key="'samecate_' + val.model" style="padding:10px;width:50%" @click="changeModel(val.model)">
                  <div style="height: 80px;overflow: hidden;"><img :src="val.url" style="width:100%"></div>
                  <div style="height: 30px;height: 30px;font-size:14px;text-align: center;margin-top:5px;">{{ val.model }}</div>
                </div>
              </div>
            </van-swipe-item>
            <template #indicator>
              <div class="custom-indicator">{{ current + 1 }}/{{ product.samecates.length }}</div>
            </template>
          </van-swipe>
        </template>
      </van-card>
    </template>
    <div v-else style="margin:200px auto;text-align: center; font-size:25px;color:#999">
      No data
    </div>
  </div>
</template>

<script>
import ProductTryon from '@/templates/product/tryon'
import Vue from 'vue';
import { Card, Swipe, SwipeItem } from 'vant';
import 'vant/lib/card/style';
import 'vant/lib/swipe/style';
Vue.use(Card);
Vue.use(Swipe);
Vue.use(SwipeItem);
const resource = '/mobile/index/index'
export default {
  name: 'TemplateProductDetail',
  components: {
    ProductTryon
  },
  props: {
    model: {
      type: String,
      default () {
        return ''
      }
    }
  },
  computed: {
  },
  data () {
    return {
      loading: false,
      toast: null,
      product: null,
      current: 0,  
      tryonShow: false
    }
  },
  async mounted () {
    this.toast = this.$createToast({
      txt: 'Loading...',
      mask: true
    })
    await this.load(this.model)

  },
  methods: {
    // 加载列表
    async load (model) {
      this.toast.show()
      this.loading = false
      const result = await this.$http.post(resource, {
        op: 'getDetail',
        model: model
      })
      this.product = result.data
      this.loading = true
      this.toast.hide()
    },
    changeSwiper (index) {
      this.current = index
    },
    changeModel (model) {
      this.product = null
      this.load(model)
      this.$emit('changeModel', model)
    },
    openFaceDialog () {
      this.tryonShow = true
    }
  }
}
</script>

<style>
.cardHeader {
  font-size: 16px;
  font-weight: bold;
  margin: 10px auto;
}

.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
